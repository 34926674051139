import './AryzaButton.css';

import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

const AryzaButton = (props: AryzaButtonProps) => {
  return (
    <button className='button' {...props}>
      {props.children}
    </button>
  );
};

export default AryzaButton;

type ButtonType = 'default' | 'primary' | 'dashed' | 'text';

interface BaseButtonProps {
  disabled?: boolean;
  'btn-type'?: ButtonType;
}

type NativeButtonProps = BaseButtonProps & ButtonHTMLAttributes<HTMLElement>;
type AnchorButtonProps = BaseButtonProps & AnchorHTMLAttributes<HTMLElement>;
type AryzaButtonProps = Partial<NativeButtonProps & AnchorButtonProps>;
