import './AuthLayout.css';

import React from 'react';

import LanguageSelect, { LanguageOption } from '../LanguageSelect/LanguageSelect';

const AuthLayout = (props: AuthLayoutProps) => {
  return (
    <div
      className={`auth-outer-layout ${props.className ?? ''}`}
      style={{ userSelect: 'none', ...props.style }}
    >
      <div className='auth-inner-layout'>
        <div className='auth-inner-left-layout'>
          <div className='auth-inner-left-layout-logo-area'>
            <img src={props.logoSrc} alt='logo' style={props.logoStyle} />
          </div>
        </div>
        <div className='auth-inner-right-layout'>
          <div className='auth-inner-right-layout-inner'>
            <div className='auth-inner-right-layout-inner-header-area'>
              <div className='auth-inner-right-layout-inner-header-area-title'>
                <span>{props.title}</span>
              </div>
              <div className='auth-inner-right-layout-inner-header-area-language'>
                {props.language && (
                  <LanguageSelect
                    fontColor='#7f7f7f'
                    currentLanguage={props.language.currentLanguage}
                    options={props.language.options}
                    onChange={props.language.onChange}
                  />
                )}
              </div>
            </div>
            <div className='auth-inner-right-layout-inner-content-area'>{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface AuthLayoutProps {
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  language?: languageProps | undefined;
  logoSrc: string | undefined;
  logoStyle?: React.CSSProperties | undefined;
  title: string;
  children: React.ReactNode;
}

export interface languageProps {
  options: LanguageOption[];
  currentLanguage: string;
  onChange: (e: string) => void;
}

export default AuthLayout;
