import './TopHeader.css';

import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import UserCenteredIcon from '../../svg-icons/UserCenteredIcon';
import LanguageSelect, { LanguageSelectProps } from '../LanguageSelect/LanguageSelect';
import UserCenterMenu from '../UserCenterMenu/UserCenterMenu';
const TopHeader = (props: TopHeaderProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const ShowUserCenterMenu = () => {
    setShowMenu(!showMenu);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={props.className} style={props.style}>
      <div className={'top-header'}>
        <div className={'top-header-aryza-logo'}>
          <img src={props.logoSrc} alt='logo' style={props.logoStyle} />
        </div>
        <a href='#' onClick={ShowUserCenterMenu} className='top-header-user-centered'>
          <UserCenteredIcon />
        </a>
        <div ref={menuRef}>
          {showMenu && <UserCenterMenu htmlCode={props.userCenterHtmlCode} />}
        </div>

        {props.LanguageSelectProps !== undefined && (
          <div className={'top-header-language-select'}>
            <LanguageSelect
              options={props.LanguageSelectProps.options}
              onChange={props.LanguageSelectProps.onChange}
              currentLanguage={props.LanguageSelectProps.currentLanguage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface TopHeaderProps {
  className?: string | undefined;
  style?: CSSProperties | undefined;
  logoSrc?: string;
  logoStyle?: CSSProperties | undefined;
  userCenterHtmlCode?: string;
  LanguageSelectProps?: LanguageSelectProps;
}

export default TopHeader;
