import './LanguageSelect.css';

import React, { useEffect, useRef, useState } from 'react';
const LanguageSelect = (props: LanguageSelectProps) => {
  const setCookie = (name: string, value: string | undefined, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const getCookie = (name: string) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const eraseCookie = (name: string) => {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  const cookieLanguageValue = getCookie('Language-Value');
  const cookieLanguageLabel = getCookie('Language-Label');

  const cookieLanguageOption = (): LanguageOption => ({
    value: cookieLanguageValue as string,
    label: cookieLanguageLabel as string,
  });

  let currentLanguage = props.options.find((option) => option.value === props.currentLanguage);

  if (cookieLanguageValue !== null && cookieLanguageLabel !== null) {
    currentLanguage = cookieLanguageOption();
  }

  const [selectedOption, setSelectedOption] = useState<LanguageOption>(
    currentLanguage ?? props.options[0],
  );
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  setCookie('Language-Value', currentLanguage?.value, 7);
  setCookie('Language-Label', currentLanguage?.label, 7);

  const handleOptionClick = (option: LanguageOption) => {
    eraseCookie('Language-Value');
    eraseCookie('Language-Label');
    setSelectedOption(option);
    setIsOptionsVisible(false);
    props.onChange(option.value);
    setCookie('Language-Value', option.value, 7);
    setCookie('Language-Label', option.label, 7);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef?.current && event.target && !selectRef.current.contains(event.target as Node)) {
        setIsOptionsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isOptionsVisible]);

  return (
    <div
      className='selectContainer'
      onClick={() => setIsOptionsVisible(!isOptionsVisible)}
      ref={selectRef}
    >
      <div id='selectedLang' className='customSelect'>
        <span style={{ color: props.fontColor ?? 'white' }}>{selectedOption.label}</span>
      </div>
      {isOptionsVisible && (
        <div className='customOptionsContainer'>
          {props.options.map((option, index) => (
            <div
              key={index}
              className='customSelectOption'
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export interface LanguageOption {
  value: string;
  label: string;
}

export interface LanguageSelectProps {
  fontColor?: string | undefined;
  currentLanguage: string;
  options: LanguageOption[];
  onChange: (value: string) => void;
}
export default LanguageSelect;
