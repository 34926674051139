import React from 'react';

const UserCenteredIcon = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='17.5758' fill='#4C60F2' stroke='#4C60F2' strokeWidth='4.84848' />
      <path
        d='M12.7273 29.4543C12.7272 25.4377 15.9834 22.1816 20 22.1816C24.0166 22.1816 27.2727 25.4377 27.2727 29.4543'
        stroke='#FAFAFC'
        strokeWidth='3.63636'
      />
      <circle
        cx='20'
        cy='13.6967'
        r='2.42424'
        fill='#FAFAFC'
        stroke='#FAFAFC'
        strokeWidth='3.63636'
      />
    </svg>
  );
};

export default UserCenteredIcon;
