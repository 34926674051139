import './Error.css';

import React, { CSSProperties } from 'react';
interface ErrorInfo {
  ErrorMessage: string;
  ErrorCode: string;
  ErrorDescription: string;
}
interface Error {
  className?: string | undefined;
  style?: CSSProperties | undefined;
  Error: ErrorInfo;
}
const ErrorComponent = (props: Error) => {
  return (
    <div className={props.className} style={props.style}>
      <div className={'error-page'}>
        <h2 className={'error-message'}>Error:{props.Error.ErrorMessage}</h2>
        <h2 className={'error-code'}>Error Code:{props.Error.ErrorCode}</h2>
        <h2 className={'error-message'}>{props.Error.ErrorDescription}</h2>
      </div>
    </div>
  );
};

export default ErrorComponent;
