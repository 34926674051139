import './PasswordRecovery.css';

import React, { CSSProperties, FormEvent, useEffect, useRef, useState } from 'react';

import emailIcon from '../../images/email.svg';
import AryzaButton from '../AryzaButton/AryzaButton';
import AryzaIconInput from '../AryzaIconInput/AryzaIconInput';
import AuthLayout, { languageProps } from '../AuthLayout/AuthLayout';
import NotificationBox from '../NotificationBox/NotificationBox';

const commonStyle: CSSProperties = { width: '330px', height: '35px' };

const PasswordRecovery = (props: PasswordRecoveryProps) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<string>('');
  const [messageType, setMessageType] = useState<'info' | 'error'>('info');
  const [email, setEmail] = useState<string>('');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const emailIsEmpty = email.trim().length <= 0;
    if (emailIsEmpty) {
      setMessage('Please enter your email address.');
      setMessageType('error');
      if (emailIsEmpty && emailRef.current) emailRef.current.focus();
      if (props.onEmptyValueError) {
        props.onEmptyValueError(emailIsEmpty);
      }
      e.preventDefault();
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmailValid = emailRegex.test(email.trim());
    if (!isEmailValid) {
      setMessage('Please enter a valid email address.');
      setMessageType('error');
      if (emailRef.current) emailRef.current.focus();
      if (props.onInvalidEmailError) {
        props.onInvalidEmailError(isEmailValid);
      }
      e.preventDefault();
      return;
    }

    if (!props.formAction) {
      e.preventDefault();
      if (props.onBtnClick) {
        props.onBtnClick(email.trim());
      }
    }
  };

  useEffect(() => {
    setMessage(props.message ?? '');
    setMessageType(props.messageType ?? 'info');
  }, [props.message, props.messageType]);

  return (
    <AuthLayout
      className={`password-recovery-container ${props.className ?? ''}`}
      style={props.style}
      logoSrc={props.logoSrc}
      logoStyle={props.logoStyle}
      title='Reset password'
      language={props.language}
    >
      <NotificationBox
        title='Can not access your account ?'
        content='Please enter the e-mail address of your account below and we will e-mail a link to reset your password.'
        type='info'
        style={{
          width: commonStyle.width,
        }}
      />
      <form
        action={props.formAction}
        method={props.formMethod}
        style={{ display: 'block' }}
        onSubmit={handleSubmit}
      >
        <div style={{ marginTop: '1rem' }}>
          <AryzaIconInput
            ref={emailRef}
            icon={emailIcon}
            iconStyle={{ width: '20px', height: '20px' }}
            style={{ ...commonStyle }}
            placeholder='E-mail'
            aria-label='email'
            onChange={handleEmailChange}
            showInvalidValueError={message.length > 0}
            name={props.emailInputName}
          />
        </div>
        <div className='btn-container'>
          <AryzaButton btn-type={'primary'} style={{ ...commonStyle }}>
            Send e-mail
          </AryzaButton>
        </div>
        <p
          className='invalid-value-tip'
          style={{
            visibility: message.length > 0 ? 'visible' : 'hidden',
            color: messageType === 'error' ? '#ff0000' : '#008000',
          }}
        >
          {message}
        </p>
      </form>
    </AuthLayout>
  );
};

interface PasswordRecoveryProps {
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  language?: languageProps | undefined;
  logoSrc: string | undefined;
  logoStyle?: React.CSSProperties | undefined;
  onBtnClick?: (email: string) => void;
  onEmptyValueError?: (isEmailEmpty: boolean) => void;
  onInvalidEmailError?: (isEmailFormatError: boolean) => void;
  onRecoverClick?: () => void;
  formAction?: string;
  formMethod?: 'get' | 'post' | undefined;
  emailInputName?: string;
  message?: string;
  messageType?: 'error' | 'info';
}

export default PasswordRecovery;
