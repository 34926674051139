import './UserCenterMenu.css';

const UserCenterMenu = (props: Props) => {
  if (!props.htmlCode) {
    return null;
  }
  return (
    <div>
      <div
        className='user-dorpdown-menu'
        dangerouslySetInnerHTML={{ __html: props.htmlCode }}
      ></div>
    </div>
  );
};

interface Props {
  htmlCode?: string;
}
export default UserCenterMenu;
