import './AryzaIconInput.css';

import React, { forwardRef, HTMLInputTypeAttribute, Ref } from 'react';

const AryzaIconInput = forwardRef((props: AryzaIconInputProps, ref: Ref<HTMLInputElement>) => {
  const handleClick = () => {
    if (ref && 'current' in ref && ref.current) {
      ref.current.focus();
    }
  };

  return (
    <div className='aryza-icon-input-container' style={props.style}>
      <div className='aryza-icon-container'>
        {typeof props.icon === 'string' ? (
          <img src={props.icon} style={props.iconStyle} alt='input icon' />
        ) : (
          props.icon
        )}
      </div>
      <div
        className={`aryza-input-container ${
          props.showInvalidValueError ? 'invalid-value-error' : ''
        }`}
        onClick={handleClick}
      >
        <input
          placeholder={props.placeholder}
          aria-label={props['aria-label']}
          ref={ref}
          type={props.type}
          onChange={props.onChange}
          onInput={props.onInput}
          name={props.name}
        />
      </div>
    </div>
  );
});

AryzaIconInput.displayName = 'AryzaIconInput';

export default AryzaIconInput;

interface AryzaIconInputProps {
  style?: React.CSSProperties;
  icon: React.ReactNode | string;
  iconStyle?: React.CSSProperties;
  placeholder?: string;
  'aria-label'?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  showInvalidValueError?: boolean;
  name?: string;
}
