import './NavBar.css';

import React, { useEffect, useState } from 'react';

import CollapseIcon from '../../svg-icons/CollapseIcon';
import ExpandIcon from '../../svg-icons/ExpandIcon';

interface NavBarProps {
  collapsedWidth?: string;
  expandedWidth?: string;
  height?: string;
  navItems: NavItem[];
  onMenuClick?: (index: number, link: string) => void;
}

export interface NavItem {
  label: string;
  icon: string;
  link: string;
  visible: string;
}

function NavBarItem(props: {
  icon: string;
  label: string;
  style?: React.CSSProperties;
  className?: string;
  index: number;
  link: string;
  collapsed: boolean;
  onClick?: (index: number, link: string) => void;
  selected: boolean;
}) {
  return (
    <div
      className={`navBar-menu-item-container  ${
        props.selected ? 'navBar-menu-item-container-hover' : ''
      }`}
      onClick={() => props.onClick && props.onClick(props.index, props.link)}
      role={'button'}
    >
      <img src={props.icon} alt='menu item' className='navBar-menu-icon' />
      <span className='navBar-menu-label' hidden={props.collapsed}>
        {props.label}
      </span>
    </div>
  );
}

function NavBar(props: NavBarProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number>(-1);

  const expandedWidth = props.expandedWidth ?? '160px';
  const collapsedWidth = props.collapsedWidth ?? '70px';
  const height = props.height ?? '100%';

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const [navItems, setNavItems] = useState(props.navItems);
  const handleClick = (index: number, link: string) => {
    props.onMenuClick && props.onMenuClick(index, link);
  };

  useEffect(() => {
    let path = window.location.href.replace('//', '');
    const queryIndex = path.indexOf('?');
    if (queryIndex >= 0) {
      path = path.substring(path.indexOf('/'), queryIndex);
    } else {
      path = path.substring(path.indexOf('/'));
    }

    const visibleItems = props.navItems.filter((x) => x.visible === 'true');
    setNavItems(visibleItems);

    const selectedIdx = visibleItems.findIndex((x) => x.link === path);
    setSelectedMenuIndex(selectedIdx);
  }, [window.location.href, props.navItems]);

  return (
    <div
      className={`navBar ${collapsed ? 'navBar-collapsed' : ''}`}
      style={{ width: collapsed ? collapsedWidth : expandedWidth, height: height }}
    >
      <div className='navBar-menu-container'>
        <div
          className='navBar-menu-selected-block'
          style={{ top: `${selectedMenuIndex * 70}px` }}
          hidden={selectedMenuIndex < 0}
        />
        {navItems.map(({ label, icon, link, visible }, index) => {
          return (
            visible === 'true' && (
              <NavBarItem
                key={index}
                icon={icon}
                label={label}
                index={index}
                link={link}
                collapsed={collapsed}
                onClick={handleClick}
                selected={selectedMenuIndex === index}
              />
            )
          );
        })}
      </div>
      <div className='collapse-btn-container' onClick={handleToggle}>
        {collapsed ? <ExpandIcon /> : <CollapseIcon />}
      </div>
    </div>
  );
}

export default NavBar;
