import './LoginUI.css';

import { useAtom } from 'jotai';
import React, { CSSProperties, FormEvent, useCallback, useEffect, useRef, useState } from 'react';

import lockIcon from '../../images/lock.svg';
import userIcon from '../../images/user.svg';
import AryzaButton from '../AryzaButton/AryzaButton';
import AryzaIconInput from '../AryzaIconInput/AryzaIconInput';
import AuthLayout, { languageProps } from '../AuthLayout/AuthLayout';
import { passwordAtom, usernameAtom } from '../store/JotaiStore';

const commonStyle: CSSProperties = { width: '330px', height: '35px' };

const LoginUI = (props: LoginUIProps) => {
  const [username, setUsername] = useAtom(usernameAtom);
  const [password, setPassword] = useAtom(passwordAtom);
  const [usernameEmptyValueError, setUsernameEmptyValueError] = useState<boolean>(false);
  const [passwordEmptyValueError, setPasswordEmptyValueError] = useState<boolean>(false);
  const [needRemember, setNeedRemember] = useState<boolean>(false);
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    const usernameIsEmpty = username.trim().length <= 0;
    const passwordIsEmpty = password.trim().length <= 0;
    if (usernameIsEmpty || passwordIsEmpty) {
      setErrorMessage('Please fill in your username and password.');
      setUsernameEmptyValueError(usernameIsEmpty);
      setPasswordEmptyValueError(passwordIsEmpty);
      if (usernameIsEmpty && usernameRef.current) {
        usernameRef.current.focus();
      } else if (passwordIsEmpty && passwordRef.current) {
        passwordRef.current.focus();
      }
      if (props.onEmptyValueError) {
        props.onEmptyValueError(usernameIsEmpty, passwordIsEmpty);
      }
      e.preventDefault();
      return;
    }

    if (needRemember) {
      localStorage.setItem('login-username', username);
      localStorage.setItem('login-password', password);
    } else {
      localStorage.removeItem('login-username');
      localStorage.removeItem('login-password');
    }

    if (!props.formAction) {
      e.preventDefault();
      if (props.onLoginClick) {
        props.onLoginClick(username, password);
      }
    }
  };

  const handleUsernameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (usernameEmptyValueError) {
        setUsernameEmptyValueError(false);
      }
      if (!passwordEmptyValueError) {
        setErrorMessage('');
      }
      setUsername(e.target.value);
    },
    [usernameEmptyValueError, passwordEmptyValueError, setUsername, setErrorMessage],
  );

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (passwordEmptyValueError) {
        setPasswordEmptyValueError(false);
      }
      if (!usernameEmptyValueError) {
        setErrorMessage('');
      }
      setPassword(e.target.value);
    },
    [passwordEmptyValueError, usernameEmptyValueError, setPassword, setErrorMessage],
  );

  const handleRememberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setNeedRemember(true);
      } else {
        setNeedRemember(false);
      }
    },
    [setNeedRemember],
  );

  useEffect(() => {
    const username = localStorage.getItem('login-username');
    const password = localStorage.getItem('login-password');
    if (username && password && usernameRef.current && passwordRef.current) {
      setUsername(username);
      usernameRef.current.value = username;
      setPassword(password);
      passwordRef.current.value = password;
      setNeedRemember(true);
    }
  }, []);

  useEffect(() => {
    setErrorMessage(props.errorMessage ?? '');
  }, [props.errorMessage]);

  return (
    <AuthLayout
      className={props.className}
      style={props.style}
      logoSrc={props.logoSrc}
      logoStyle={props.logoStyle}
      title='Sign In'
      language={props.language}
    >
      <form
        action={props.formAction}
        method={props.formMethod}
        style={{ display: 'block' }}
        onSubmit={handleLogin}
      >
        <div style={{ marginTop: '1rem' }}>
          <AryzaIconInput
            ref={usernameRef}
            icon={userIcon}
            iconStyle={{ width: '20px' }}
            style={{ ...commonStyle }}
            placeholder='Username'
            aria-label='Username'
            onChange={handleUsernameChange}
            showInvalidValueError={usernameEmptyValueError}
            name={props.usernameInputName}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <AryzaIconInput
            ref={passwordRef}
            icon={lockIcon}
            iconStyle={{ width: '20px' }}
            style={{ ...commonStyle }}
            placeholder='Password'
            aria-label='Password'
            type='password'
            onChange={handlePasswordChange}
            showInvalidValueError={passwordEmptyValueError}
            name={props.passwordInputName}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <input type='hidden' name={props.rememberMeInputName} value='0' />
          <input
            type='checkbox'
            id='rememberMe'
            name={props.rememberMeInputName}
            value='1'
            onChange={handleRememberChange}
            checked={needRemember}
          />
          <label htmlFor='rememberMe' className='remember-me-label'>
            Remember me
          </label>
        </div>
        <div style={{ marginTop: '4rem' }}>
          <AryzaButton btn-type={'primary'} style={{ ...commonStyle }}>
            Login
          </AryzaButton>
        </div>
        <div style={{ marginTop: '0.5rem' }}>
          <span className='recover-link' onClick={props.onRecoverClick}>
            Recover your account
          </span>
        </div>
        <p
          className='empty-value-tip'
          style={{
            visibility: errorMessage ? 'visible' : 'hidden',
          }}
        >
          {errorMessage}
        </p>
      </form>
    </AuthLayout>
  );
};

export default LoginUI;

interface LoginUIProps {
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
  language?: languageProps | undefined;
  logoSrc: string | undefined;
  logoStyle?: React.CSSProperties | undefined;
  onLoginClick?: (username: string, password: string) => void;
  onEmptyValueError?: (isUsernameEmpty: boolean, isPasswordEmpty: boolean) => void;
  onRecoverClick?: () => void;
  formAction?: string;
  formMethod?: 'get' | 'post' | undefined;
  usernameInputName?: string;
  passwordInputName?: string;
  errorMessage?: string;
  rememberMeInputName?: string;
}
