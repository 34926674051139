import './NotificationBox.css';

import React from 'react';

import NotificationIcon from './NotificationIcon';

export default function NotificationBox(props: NotificationBoxProps) {
  return (
    <div
      hidden={props.hidden || !props.content}
      className='notification-box-container'
      style={props.style}
    >
      <div className={`inner-container ${props.type === 'error' ? 'error' : 'info'}`}>
        <div className='title-container'>
          <div className='icon'>
            <NotificationIcon color={props.type === 'error' ? '#da1c1c' : '#008000'} />
          </div>
          <span className='title'>{props.title}</span>
        </div>
        <div className='content-container'>
          <p>{props.content}</p>
        </div>
      </div>
    </div>
  );
}

interface NotificationBoxProps {
  style?: React.CSSProperties;
  type?: 'info' | 'error';
  title?: string;
  content?: string;
  hidden?: boolean | undefined;
}
