import React from 'react';

export default function NotificationIcon(props: { color?: string }) {
  return (
    <svg
      viewBox='0 0 1024 1024'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
    >
      <path
        d='M512.002558 65.290005c-246.698658 0-446.712041 200.045105-446.712041 446.712041 0 246.714008 200.013383 446.706925 446.712041 446.706925 246.687402 0 446.705901-199.992917 446.705901-446.706925C958.70846 265.335111 758.68996 65.290005 512.002558 65.290005L512.002558 65.290005zM522.936585 223.141126c40.130999 0 72.597435 32.534997 72.597435 72.576969 0 40.135092-32.467459 72.627111-72.597435 72.627111-40.040948 0-72.599482-32.492018-72.599482-72.627111C450.331987 255.676124 482.895637 223.141126 522.936585 223.141126L522.936585 223.141126zM651.998009 771.279185 408.894226 771.279185l0-31.853475 30.442336-11.247158c17.041118-6.261611 28.384467-22.556739 28.384467-40.662095L467.721029 515.793396c0-18.137079-11.339256-34.400484-28.384467-40.634466l-30.442336-11.272741 0-32.53909 184.250374 0 0 256.172427c0 18.101263 11.341303 34.400484 28.41619 40.658002l30.438243 11.251252L651.999033 771.279185 651.998009 771.279185zM651.998009 771.279185'
        fill={props.color || '#008000'}
      ></path>
    </svg>
  );
}
